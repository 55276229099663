import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { IAppConfiguration } from '@data/interface/configuration.interface';
import { AppConfigurationService } from './app-configuration.service';
import { AuthenticationUsecase } from '@domain/usecase/authentication.usecase';
import { UserUsecase } from '@domain/usecase/user.usecase';
import { UserService } from './common/services/user.service';
import mixpanel from 'mixpanel-browser';
import { environment as devEnvironment, environment } from '../environments/environment';
import { environment as prodEnvironment } from '../environments/environment.prod';

// tslint:disable-next-line:ban-types
declare const gtag: Function;
@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
	title = 'waca-mobile-site';

	private subscription!: Subscription;
	appConfig: IAppConfiguration = { isDesktopView: false };

	mixpanelToken = !environment.production
		? devEnvironment.mixpanelToken
		: prodEnvironment.mixpanelToken;

	constructor(
		private activeRoute: ActivatedRoute,
		private appConfigurationService: AppConfigurationService,
		private authentication: AuthenticationUsecase,
		private userUsecase: UserUsecase,
		private userService: UserService,
		private router: Router
	) {}

	ngOnInit(): void {
		mixpanel.init(this.mixpanelToken);
		this.subscription = this.appConfigurationService.appConfiguration$.subscribe((data) => {
			this.appConfig = data;
		});

		this.router.events.subscribe((evt) => {
			if (!(evt instanceof NavigationEnd)) {
				return;
			}
			window.scrollTo(0, 0);

			// Get query parameters from the active route snapshot
			const snapshot = this.activeRoute.snapshot;
			const queryParams = snapshot.queryParams;
			const token = queryParams['at'];
			const refreshToken = queryParams['rt'];

			if (token) {
				this.authentication.setToken(token);
				this.authentication.setRefreshToken(refreshToken);
				// Do something with the token
			} else {
				if (this.authentication.isUserLoggedIn()) {
					this.userUsecase.getDetailProfile().subscribe({
						next: (response: any) => {
							this.userService.emitEvent(response);
						}
					});
				}
			}
		});
	}
}
