import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import * as Sentry from '@sentry/angular-ivy';

// sentry setup
Sentry.init({
	enabled: true,
	dsn: 'https://68ffc1cfe1f849968196e4ec7b728721@o4505515054006272.ingest.sentry.io/4505515055775744',
	integrations: [
		new Sentry.BrowserTracing({
			// Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
			tracePropagationTargets: ['localhost', 'https:wacaku.com', 'https:wacaku.com/api/'],
			routingInstrumentation: Sentry.routingInstrumentation
		}),
		new Sentry.Replay()
	],
	// Performance Monitoring
	tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
	// Session Replay
	replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
	replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

if (environment.production) {
	try {
		enableProdMode();
	} catch (exception) {
		// tslint:disable-next-line:no-console
		console.error(
			'BUGFIX: calling isDevMode() in imports before enableProdMode() ' +
				'throws exception - https://github.com/angular/angular-cli/issues/8340#\n',
			exception
		);
	}
}

function bootstrap() {
	platformBrowserDynamic()
		.bootstrapModule(AppModule)
		.catch((err) => {});
}

if (document.readyState === 'complete') {
	bootstrap();
} else {
	document.addEventListener('DOMContentLoaded', bootstrap);
}
