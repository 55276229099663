import { NgModule } from '@angular/core';
import { RouterModule, Routes, UrlSegment } from '@angular/router';
import { AuthGuard } from './common/authGuard/Auth.guard';

const routes: Routes = [
	{
		path: '',
		loadChildren: () =>
			import('./presentation/journey/revamp-landing/home/home.module').then(
				(m) => m.HomeModule
			)
	},
	{
		path: 'home',
		loadChildren: () =>
			import('./presentation/journey/homepage/home/home.module').then((m) => m.HomeModule)
	},
	{
		path: 'browse/genre',
		loadChildren: () =>
			import('./presentation/journey/homepage/browse-by-genre/browse-by-genre.module').then(
				(m) => m.BrowseByGenreModule
			)
	},
	{
		path: 'menulis/list-karya',
		canActivate: [AuthGuard],
		loadChildren: () =>
			import('./presentation/journey/homepage/list-karya/list-karya.module').then(
				(m) => m.ListKaryaModule
			)
	},
	// {
	// 	path: 'events',
	// 	loadChildren: () =>
	// 		import('./presentation/journey/events/list-events/list-events.module').then(
	// 			(m) => m.ListEventsModule
	// 		)
	// },
	{
		path: 'monetisaku/dashboard',
		loadChildren: () =>
			import(
				'./presentation/journey/monetisaku/dashboard-monetisaku/dashboard-monetisaku.module'
			).then((m) => m.DashboardMonetisakuModule)
	},
	{
		path: 'misc',
		loadChildren: () =>
			import('./presentation/journey/pengaturan/pengaturan.module').then(
				(m) => m.PengaturanModule
			)
	},
	{
		path: 'create-post/:id_post',
		loadChildren: () =>
			import('./presentation/journey/writing/create-post-v2/create-post-v2.module').then(
				(m) => m.CreatePostV2Module
			),
		canActivate: [AuthGuard]
	},
	{
		path: 'create-post/:id_post/create-chapter',
		loadChildren: () =>
			import('./presentation/journey/writing/create-chapter/create-chapter.module').then(
				(m) => m.CreateChapterModule
			),
		canActivate: [AuthGuard]
	},
	// {
	// 	path: 'event/detail/:url_web',
	// 	loadChildren: () =>
	// 		import('./presentation/journey/events/detail-event/detail-event.module').then(
	// 			(m) => m.DetailEventModule
	// 		)
	// },
	// {
	// 	path: 'event/success-submit',
	// 	loadChildren: () =>
	// 		import(
	// 			'./presentation/journey/events/success-submit-events/success-submit-events.module'
	// 		).then((m) => m.SuccessSubmitEventsModule)
	// },
	// {
	// 	path: 'events/upcoming',
	// 	loadChildren: () =>
	// 		import('./presentation/journey/events/upcoming-events/upcoming-events.module').then(
	// 			(m) => m.UpcomingEventsModule
	// 		)
	// },
	{
		path: 'monetisaku/homepage',
		loadChildren: () =>
			import(
				'./presentation/journey/monetisaku/homepage-monetisaku/homepage-monetisaku.module'
			).then((m) => m.HomepageMonetisakuModule)
	},
	{
		path: 'monetisaku/select-post',
		loadChildren: () =>
			import(
				'./presentation/journey/monetisaku/select-post-monetisaku/select-post-monetisaku.module'
			).then((m) => m.SelectPostMonetisakuModule)
	},
	{
		path: 'monetisaku/enrollment/status/:enrollment_id',
		loadChildren: () =>
			import(
				'./presentation/journey/monetisaku/status-monetisaku/status-monetisaku.module'
			).then((m) => m.StatusMonetisakuModule)
	},
	{
		path: 'monetisaku/terms-condition',
		loadChildren: () =>
			import(
				'./presentation/journey/monetisaku/terms-condition-monetisaku/terms-condition-monetisaku.module'
			).then((m) => m.TermsConditionMonetisakuModule)
	},
	{
		path: 'monetisaku/enrollment/list',
		loadChildren: () =>
			import(
				'./presentation/journey/monetisaku/enrollment-list-monetisaku/enrollment-list-monetisaku.module'
			).then((m) => m.EnrollmentListMonetisakuModule)
	},
	{
		path: 'monetisaku/withdrawal/request',
		loadChildren: () =>
			import('./presentation/journey/monetisaku/withdrawal/request/request.module').then(
				(m) => m.RequestModule
			)
	},
	{
		path: 'monetisaku/withdrawal/history',
		loadChildren: () =>
			import('./presentation/journey/monetisaku/withdrawal/history/history.module').then(
				(m) => m.HistoryModule
			)
	},
	// {
	// 	path: 'join-event/:event_id',
	// 	loadChildren: () =>
	// 		import('./presentation/journey/events/join-event/join-event.module').then(
	// 			(m) => m.JoinEventModule
	// 		)
	// },
	{
		path: 'search',
		loadChildren: () =>
			import('./presentation/journey/search/search.module').then((m) => m.SearchModule)
	},
	{
		path: 'browse',
		loadChildren: () =>
			import('./presentation/journey/browse/browse.module').then((m) => m.BrowseModule)
	},
	{
		path: 'profile',
		loadChildren: () =>
			import('./presentation/journey/profile/profile/profile.module').then(
				(m) => m.ProfileModule
			),
		canActivate: [AuthGuard]
	},

	{
		matcher: (url) => {
			if (url.length === 1 && url[0].path.match(/^@[.\w]+$/gm)) {
				return {
					consumed: url,
					posParams: {
						username: new UrlSegment(url[0].path.substr(1), {})
					}
				};
			}

			return null;
		},
		loadChildren: () =>
			import('./presentation/journey/profile/other-profile/other-profile.module').then(
				(m) => m.OtherProfileModule
			)
	},
	{
		matcher: (url) => {
			if (
				url.length === 2 &&
				url[0].path === 'penerbit' &&
				url[1].path.match(/^@[.\w]+$/gm)
			) {
				return {
					consumed: url,
					posParams: {
						username: new UrlSegment(url[1].path.substr(1), {})
					}
				};
			}

			return null;
		},
		loadChildren: () =>
			import('./presentation/journey/profile/penerbit-profile/penerbit-profile.module').then(
				(m) => m.PenerbitProfileModule
			)
	},
	{
		matcher: (url) => {
			if (
				url.length === 2 &&
				url[0].path.match(/^@[.\w]+$/gm) &&
				(url[1].path === 'following' || url[1].path === 'followers')
			) {
				return {
					consumed: url,
					posParams: {
						username: new UrlSegment(url[0].path.substr(1), {})
					}
				};
			}

			return null;
		},
		loadChildren: () =>
			import('./presentation/journey/profile/follow/follow.module').then(
				(m) => m.FollowModule
			)
	},
	{
		path: 'profile-detail',
		loadChildren: () =>
			import('./presentation/journey/profile/detail-profile/detail-profile.module').then(
				(m) => m.DetailProfileModule
			),
		canActivate: [AuthGuard]
	},
	{
		path: 'post/:post_id/:chapter_id',
		loadChildren: () =>
			import('./presentation/journey/reading/read/read.module').then((m) => m.ReadModule)
	},
	{
		path: 'post-review/:post_id',
		loadChildren: () =>
			import('./presentation/journey/reading/detail-review/detail-review.module').then(
				(m) => m.DetailReviewModule
			),
		canActivate: [AuthGuard]
	},
	{
		path: 'comments/:chapter_id',
		loadChildren: () =>
			import('./presentation/journey/reading/comments/comments.module').then(
				(m) => m.CommentsModule
			)
	},
	{
		path: 'post/:post_id',
		loadChildren: () =>
			import(
				'./presentation/journey/reading/revamped-detail-post/revamped-detail-post.module'
			).then((m) => m.RevampedDetailPostModule)
	},
	// {
	// 	path: 'revamped-post/:post_id',
	// 	loadChildren: () =>
	// 		import(
	// 			'./presentation/journey/reading/revamped-detail-post/revamped-detail-post.module'
	// 		).then((m) => m.RevampedDetailPostModule)
	// },
	{
		path: 'topup',
		loadChildren: () =>
			import('./presentation/journey/topup/topup-home/topup.module').then(
				(m) => m.TopupModule
			),
		canActivate: [AuthGuard]
	},
	{
		path: 'topup/success',
		loadChildren: () =>
			import('./presentation/journey/topup/topup-success/topup-success.module').then(
				(m) => m.TopupSuccessModule
			),
		canActivate: [AuthGuard]
	},
	{
		path: 'topup/syarat-ketentuan',
		loadChildren: () =>
			import(
				'./presentation/journey/topup/components/syarat-ketentuan/syarat-ketentuan.module'
			).then((m) => m.SyaratKetentuanModule),
		canActivate: [AuthGuard]
	},
	{
		path: 'notifikasi',
		loadChildren: () =>
			import('./presentation/journey/notifikasi/notifikasi.module').then(
				(m) => m.NotifikasiModule
			),
		canActivate: [AuthGuard]
	},
	{
		path: 'about/partnership',
		loadChildren: () =>
			import('./presentation/journey/landing/partnership/partnership.module').then(
				(m) => m.PartnershipModule
			)
	},
	{
		path: 'aini/package',
		loadChildren: () =>
			import('./presentation/journey/landing/ai-waca/ai-waca.module').then(
				(m) => m.AiWacaModule
			)
	},
	{
		path: 'about/writer/info',
		loadChildren: () =>
			import('./presentation/journey/revamp-landing/info-writer/info-writer.module').then(
				(m) => m.InfoWriterModule
			)
	},
	{
		path: 'about/writer/monetisaku',
		loadChildren: () =>
			import('./presentation/journey/revamp-landing/monetisaku/monetisaku.module').then(
				(m) => m.MonetisakuModule
			)
	},
	{
		path: 'about/event',
		loadChildren: () =>
			import('./presentation/journey/revamp-landing/event/event.module').then(
				(m) => m.EventModule
			)
	},
	{
		path: 'about/business',
		loadChildren: () =>
			import('./presentation/journey/revamp-landing/business/business.module').then(
				(m) => m.BusinessModule
			)
	},
	{
		path: 'about/program',
		loadChildren: () =>
			import('./presentation/journey/revamp-landing/program/program.module').then(
				(m) => m.ProgramModule
			)
	},
	{
		path: 'about/writer/aini',
		loadChildren: () =>
			import('./presentation/journey/landing/landing-aini/landing-aini.module').then(
				(m) => m.LandingAiniModule
			)
	},
	{
		path: 'pustaka',
		loadChildren: () =>
			import('./presentation/journey/shop/shop.module').then((m) => m.ShopModule)
	},
	{
		path: 'academic',
		loadChildren: () =>
			import('./presentation/journey/landing/landing-academic/landing-academic.module').then(
				(m) => m.LandingAcademicModule
			)
	},
	{
		path: 'campaign',
		loadChildren: () =>
			import('./presentation/journey/landing/landing-campaign/landing-campaign.module').then(
				(m) => m.LandingCampaignModule
			)
	},
	{
		path: 'revamp-landing/home',
		loadChildren: () =>
			import('./presentation/journey/revamp-landing/home/home.module').then(
				(m) => m.HomeModule
			)
	},

	//REVAMP-LANDING
	{
		path: 'revamp-landing/home',
		loadChildren: () =>
			import('./presentation/journey/revamp-landing/home/home.module').then(
				(m) => m.HomeModule
			)
	},
	{
		path: 'revamp-landing/info-writer',
		loadChildren: () =>
			import('./presentation/journey/revamp-landing/info-writer/info-writer.module').then(
				(m) => m.InfoWriterModule
			)
	},
	{
		path: 'revamp-landing/monetisaku',
		loadChildren: () =>
			import('./presentation/journey/revamp-landing/monetisaku/monetisaku.module').then(
				(m) => m.MonetisakuModule
			)
	},
	{
		path: 'revamp-landing/event',
		loadChildren: () =>
			import('./presentation/journey/revamp-landing/event/event.module').then(
				(m) => m.EventModule
			)
	},
	{
		path: 'revamp-landing/program',
		loadChildren: () =>
			import('./presentation/journey/revamp-landing/program/program.module').then(
				(m) => m.ProgramModule
			)
	},
	{
		path: 'revamp-landing/business',
		loadChildren: () =>
			import('./presentation/journey/revamp-landing/business/business.module').then(
				(m) => m.BusinessModule
			)
	},
	{
		path: 'acquisition/:slug',
		loadChildren: () =>
			import('./presentation/journey/akuisisi/akuisisi.module').then((m) => m.AkuisisiModule)
	},
	{
		path: 'acquisition/join/:acquisition_id',
		loadChildren: () =>
			import('./presentation/journey/akuisisi/join-akuisisi/join-akuisisi.module').then(
				(m) => m.JoinAcquisitionModule
			)
	},
	{
		path: 'acquisition/join/:acquisition_id/success-submit',
		loadChildren: () =>
			import(
				'./presentation/journey/akuisisi/success-submit-akuisisi/success-submit-akuisisi.module'
			).then((m) => m.SuccessSubmitAkuisisiModule)
	},
	{
		path: '**',
		redirectTo: '/'
	}
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes, {
			initialNavigation: 'enabledBlocking'
		})
	],
	exports: [RouterModule]
})
export class AppRoutingModule { }
