import { Observable } from 'rxjs';
import { IBasePost, IRequestPublishPost } from '@data/interface/post.interface';

export abstract class PostRepository {
	abstract publishPost(params: IBasePost): Observable<any>;
	abstract createPostAsDraft(params: Partial<IBasePost>): Observable<any>;
	abstract updatePostAsDraft(params: Partial<IBasePost>, id: string): Observable<any>;
	abstract updatePost(params: IRequestPublishPost, id: string): Observable<any>;
	abstract savePendingChanges(
		postId: string,
		params: Omit<IBasePost, 'id' | 'isMonetized'>
	): Observable<any>;
	abstract getPostAllPostByCategoryAndGenre(query: string): Observable<any>;
	abstract getDetailPost(id: string): Observable<any>;
	abstract getDetailPostForWriter(id: string): Observable<any>;
	abstract deletePost(id: string): Observable<any>;
	abstract getRandomPost(query: object): Observable<any>;
	abstract getPopularMonetizedPost(query: string): Observable<any>;
	abstract getPopularPost(query: string): Observable<any>;
	abstract getLatestPost(query: string): Observable<any>;
	abstract getDefaultCoverImage(): Observable<any>;
	abstract getEligiblePostForWritingEvent(): Observable<any>;
	abstract getPopularWriter(query: string): Observable<any>;
}
