export const environment = {
	production: true,
	msUserBaseUrl: 'https://wacaku.com/api/user',
	msFileBaseUrl: 'https://wacaku.com/api/file',
	msPaymentBaseUrl: 'https://wacaku.com/api/payment',
	msAnalyticsBaseUrl: 'https://wacaku.com/api/analytics',
	msStoreBaseUrl: 'https://wacaku.com/api/store',
	msNotificationBaseUrl: 'https://wacaku.com/api/notification',
	lsUserKey: 'cvG=@L,r@1$$bfh&vjf9$Q7V',
	lsAccessTokenKey: 'hzTEZH9y#mw=!Yiubn8l67LdB',
	lsRefreshTokenKey: '5e%XoO6hgKrTQs,cA$AE1i0D+',
	mixpanelToken: '7fb420743dfb2f2f80fdfdf1074edf51',
	INKCRAFT_URL: 'https://inkcraft.wacaku.com/',
	SENTRY_AUTH_TOKEN: 'b305107a040440d3855986c627633997f13e360c69ca45f0900eff1dd61d3c45',
	firebase: {
		projectId: 'waca-id',
		appId: '1:1078213229789:web:746afd83634e329e35ef34',
		storageBucket: 'waca-id.appspot.com',
		apiKey: 'AIzaSyC0G6zXDFpbmNa_m2QGRovw5tojG69KMWk',
		authDomain: 'waca-id.firebaseapp.com',
		messagingSenderId: '1078213229789',
		measurementId: 'G-F6Q89HS0QW'
	}
};
